let origin = window.origin;
console.log(origin);
var WEB_BASE_URL, UNIFIED_BASE_URL, LOGICAL_BASE_URL, CLIQ_BASE_URL, VAS_BASE_URL, ENTERPRISE_URL = "";



WEB_BASE_URL = origin + "/admin";
UNIFIED_BASE_URL = origin + "/api";
LOGICAL_BASE_URL = origin + "/logical";
CLIQ_BASE_URL = origin + "/cliq";
VAS_BASE_URL = origin + "/vas";
ENTERPRISE_URL = origin + "/enterpriseAPI";


module.exports = { WEB_BASE_URL, UNIFIED_BASE_URL, LOGICAL_BASE_URL, CLIQ_BASE_URL, VAS_BASE_URL, ENTERPRISE_URL };

