import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import cookie from "react-cookies";
import axios from 'axios';
import {  UNIFIED_BASE_URL } from '../webbase';
function SetUnicard() {
    const [commission, setCommission] = useState('')
    const token = cookie.load("token");
    const onSubmit = (e) =>{
        e.preventDefault();
        const id = toast.loading("Please wait...");
       axios.post(UNIFIED_BASE_URL + "/manage/set-unicard-commission",
        {
         
          "commission": commission,
        }
        ,{
          headers: {
            authorization: token,
            commission:commission
          },
        })
        .then((res) => {
          //check if data isnt null
          if (res.data.status === "2000") {
            toast.update(id, {
              render: res.data.description,
              type: "success",
              isLoading: false,
            });
            window.location.reload()
          }
          
          else {
            toast.update(id, {
              render: res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }

  return (
    <main className="page-content">
         <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage unicard commission
                </li>
              </ol>
            </nav>
          </div>

         
        </div>
        <form onSubmit={onSubmit}>
        <div className='row'>
        <div className=' col-6'>
        <div className=' p-2'>
            <label className="form-label">Commission</label>
            <input
              className="form-control"
              type="text"
              required
              value={commission}
              onChange={(e) =>setCommission(e.target.value)}
              placeholder="10"
            />
         </div>   
        </div>

        </div>
        <div className="btn-group p-2">
              <button onClick={onSubmit}  type="button" className="btn btn-primary">
              Set commission
              </button>
        </div>
        </form>
       
       
    </main>
  )
}

export default SetUnicard